<template>
  <XyzTransitionGroup
    appear
    class="item-group"
    xyz="fade left-3"
  >
    <b-card :key="1">
      <XyzTransition
        appear
        xyz="fade up-3 delay-7"
      >
        <b-row class="mb-1">
          <b-col
            cols="12"
            xl="3"
            class="mb-1"
          >
            <v-select
              v-model="customerFilter.selectedSort"
              class="mr-1 w-100"
              label="label"
              :options="sortOptions"
              placeholder="Sortir"
              :clearable="false"
            />
          </b-col>
          <b-col
            cols="12"
            xl="3"
            class="mb-1 pl-1 pl-xl-0"
          >
            <b-form-input
              v-model="customerFilter.search"
              class="w-100"
              placeholder="Cari data"
              debounce="500"
            />
          </b-col>
          <b-col
            cols="12"
            xl="5"
            class="d-flex ml-0 ml-xl-auto justify-content-between justify-content-md-end align-items-start"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-toggle.sidebar.sidebar-right
              variant="outline-primary"
              class="mr-1"
            >
              <feather-icon
                icon="FilterIcon"
                size="16"
              />
              Filter
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :to="{ name: 'customer-add' }"
              variant="primary"
              class="flex-grow-1"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
              />
              Customer Baru
            </b-button>
          </b-col>
          <b-col
            v-if="filterMode"
            cols="12"
            class="mb-1"
          >
            <b-badge
              variant="primary"
              class="align-self-center mr-50 cursor-pointer"
              @click="resetFilter"
            >
              Reset Filter
              <feather-icon
                icon="XIcon"
              />
            </b-badge>
          </b-col>
        </b-row>
      </XyzTransition>
      <XyzTransition
        appear
        xyz="fade down-3 delay-5"
      >
        <b-overlay
          :show="loadingCustomer"
          rounded="sm"
        >
          <vue-good-table
            :columns="columns"
            :rows="customerList"
            :pagination-options="{
              enabled: true,
              perPage: customerFilter.limit,
            }"
            :sort-options="{
              enabled: false,
            }"
            style-class="vgt-table"
          >
            <empty-state-table slot="emptystate" />
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span v-if="props.column.label ==='id'">
                <span class="text-left">{{ props.column }}</span>
              </span>
            </template>
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'name'">
                <div class="d-flex align-items-center">
                  <div
                    v-b-popover.hover.top="props.row.isInAccurate ? 'Ada di Accurate' : 'Tidak ada di Accurate'"
                    class="box-status mr-75"
                    :class="props.row.isInAccurate ? 'bg-success' : 'bg-danger'"
                  />
                  <div>
                    <b-media>
                      <template #aside>
                        <b-avatar
                          size="36"
                          :text="avatarText( props.row.name )"
                          variant="light-primary"
                        />
                      </template>
                      <router-link :to="{ name: 'customer-detail', params: {id: props.row.id.toString()} }">
                        <h6 class="text-primary m-0">{{ props.row.name }}</h6>
                      </router-link>
                      <small class="d-block mt-25">
                        {{ props.row.email }}
                      </small>
                      <small
                        v-if="props.row.mobilePhone"
                        class="d-block"
                      >
                        <feather-icon
                          icon="PhoneIcon"
                          size="12"
                          class="text-muted"
                        />
                        {{ props.row.mobilePhone }}
                      </small>
                      <small class="text-muted d-block">
                        {{ props.row.customerNo }}
                      </small>
                      <small>
                        <b-badge
                          v-if="props.row.category"
                          v-b-popover.hover.top="'Kategori Customer'"
                          class="mt-25"
                          variant="light-primary"
                        >
                          {{ props.row.category }}
                        </b-badge>
                      </small>
                    </b-media>
                  </div>
                </div>
              </span>
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  :variant="props.row.status === 0 ? 'secondary' : 'primary'"
                  class="mr-50"
                >
                  {{ props.row.status === 0 ? 'Tidak Aktif' : 'Aktif' }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'balance'">
                <div v-if="props.row.customerLimitAmountValue">
                  <div class="d-flex justify-content-between">
                    <small
                      v-b-popover.hover.top="`Sisa piutang : ${formatCurrency(props.row.balance)}`"
                      class="m-0"
                    >
                      {{ shortNumber(props.row.balance) }}
                    </small>
                    <small
                      v-b-popover.hover.top="`Limit piutang : ${formatCurrency(props.row.customerLimitAmountValue)}`"
                      class="m-0"
                    >
                      {{ shortNumber(props.row.customerLimitAmountValue) }}
                    </small>
                  </div>
                  <b-progress
                    class="mt-50"
                    :value="props.row.balance"
                    :max="props.row.customerLimitAmountValue"
                  />
                </div>
                <div v-else>
                  <div class="d-flex justify-content-between">
                    <small>Balance :</small>
                    <small>{{ shortNumber(props.row.balance) }}</small>
                  </div>
                  <b-progress
                    v-b-popover.hover.top="'Customer ini tidak punya limit, sehingga Sisa piutang tidak terbatas.'"
                    class="mt-50"
                    :value="100"
                    :max="100"
                  />
                </div>
              </span>
              <span v-else-if="props.column.field === 'priceCategory'">
                <b-badge
                  v-if="props.row.priceCategory"
                  v-b-popover.hover.top="'Kategori Harga'"
                  variant="primary"
                >
                  {{ props.row.priceCategory }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'street'">
                <h6 class="m-0 font-weight-normal">
                  {{ props.row.street }}{{ props.row.city && props.row.street ? ',' : '' }}{{ props.row.city ? ` ${props.row.city}` : '' }}
                </h6>
              </span>
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    offset="-100"
                    boundary="window"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{ name: 'customer-detail', params: {id: props.row.id.toString()} }"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        class="mr-50 text-primary"
                      />
                      <span class="text-primary">Lihat Customer</span>
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item
                      :disabled="!props.row.isAssigned && $root.role !== 2"
                      :to="{ name: 'addquote', params: { selectedCustomer: props.row, resetFormState: true } }"
                    >
                      <feather-icon
                        icon="FileTextIcon"
                        class="mr-50 mb-25"
                        :class="props.row.isAssigned || $root.role === 2 ? 'text-info' : 'text-muted'"
                      />
                      <span :class="props.row.isAssigned || $root.role === 2 ? 'text-info' : 'text-muted'">Buat Quote</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :disabled="!props.row.isAssigned && $root.role !== 2"
                      :to="{ name: 'addproject', params: { selectedCustomer: props.row } }"
                    >
                      <feather-icon
                        icon="HomeIcon"
                        class="mr-50 mb-25"
                        :class="props.row.isAssigned || $root.role === 2 ? 'text-info' : 'text-muted'"
                      />
                      <span :class="props.row.isAssigned || $root.role === 2 ? 'text-info' : 'text-muted'">Buat Project</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-b-popover.hover.top="props.row.isInAccurate ? 'Hanya bisa mengedit melalui Accurate' : ''"
                      :disabled="(!props.row.isAssigned && $root.role !== 2) || props.row.isInAccurate"
                      :to="{ name: 'customer-edit', params: { id: props.row.id }}"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                        :class="!props.row.isInAccurate && (props.row.isAssigned || $root.role === 2) ? 'text-warning' : 'text-muted'"
                      />
                      <span :class="!props.row.isInAccurate && (props.row.isAssigned || $root.role === 2) ? 'text-warning' : 'text-muted'">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="props.row.status === 1 "
                      v-b-popover.hover.top="props.row.isInAccurate ? 'Hanya bisa menghapus melalui Accurate' : ''"
                      :disabled="(!props.row.isAssigned && $root.role !== 2) || props.row.isInAccurate"
                      @click="deleteCustomerData(props.row.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                        :class="!props.row.isInAccurate && (props.row.isAssigned || $root.role === 2) ? 'text-danger' : 'text-muted'"
                      />
                      <span :class="!props.row.isInAccurate && (props.row.isAssigned || $root.role === 2) ? 'text-danger' : 'text-muted'">Hapus Customer</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-else
                      v-b-popover.hover.top="props.row.isInAccurate ? 'Hanya bisa mengedit melalui Accurate' : ''"
                      :disabled="(!props.row.isAssigned && $root.role !== 2) || props.row.isInAccurate"
                      @click="restoreCustomerData(props.row.id)"
                    >
                      <feather-icon
                        icon="ShareIcon"
                        class="mr-50"
                        :class="!props.row.isInAccurate && (props.row.isAssigned || $root.role === 2) ? 'text-danger' : 'text-muted'"
                      />
                      <span :class="!props.row.isInAccurate && (props.row.isAssigned || $root.role === 2) ? 'text-danger' : 'text-muted'">Restore Customer</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="!props.row.isInAccurate && ($root.role === 2 || $root.role === 3)"
                      @click="addToAccurate(props.row.id)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50 text-danger"
                      />
                      <span class="text-danger">Tambah ke Accurate</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </template>

            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <span>Total Data : {{ customerCount }}</span>
                <div class="ml-auto">
                  <b-pagination
                    v-model="customerFilter.offset"
                    :total-rows="customerCount"
                    :per-page="customerFilter.limit"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-overlay>
      </XyzTransition>

      <b-sidebar
        id="sidebar-right"
        ref="sidebarRight"
        title="Filter"
        right
        shadow
        backdrop
        backdrop-variant="transparent"
        class="sidebar"
        @shown="fetchSomeStuff()"
        @hidden="fetchCustomers()"
      >
        <div class="px-2 py-1">
          <label>Kategori</label>
          <v-select
            v-model="customerFilter.customerCategory"
            class="w-100 mb-1"
            :options="customerCategory"
            label="name"
            placeholder="Pilih kategori"
            multiple
          />
          <label>NPWP</label>
          <b-form-input
            v-model="customerFilter.npwp"
            placeholder="NPWP"
            class="mb-25"
          />
          <label>Status</label>
          <v-select
            v-model="customerFilter.status"
            class="w-100"
            :options="[{ id: 1, label: 'Aktif' }, { id: 0, label: 'Tidak Aktif' }]"
            placeholder="Pilih status"
          />
        </div>
        <template #footer>
          <div class="px-2 py-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="secondary"
              @click="resetFilter(); $refs.sidebarRight.hide()"
            >
              Reset
            </b-button>
          </div>
        </template>
      </b-sidebar>
    </b-card>
  </XyzTransitionGroup>
</template>

<script>
import {
  BCard, BPagination, BDropdown, BDropdownItem, BDropdownDivider, BButton, BSidebar, BFormInput,
  VBToggle, BAvatar, BMedia, BOverlay, VBPopover, BRow, BCol, BBadge, BProgress,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { avatarText, formatCurrency, shortNumber } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import EmptyStateTable from '@/components/misc/EmptyStateTable.vue'
import { createHelpers } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { mapFields } = createHelpers({
  getterType: 'getCustomersField',
  mutationType: 'updateCustomersField',
})

export default {
  components: {
    BCard,
    BPagination,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton,
    BSidebar,
    BFormInput,
    BAvatar,
    BMedia,
    BOverlay,
    BRow,
    BCol,
    BBadge,
    BProgress,
    VueGoodTable,
    vSelect,
    EmptyStateTable,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-popover': VBPopover,
  },
  data() {
    return {
      avatarText,
      formatCurrency,
      shortNumber,
      perPageOptions: [25, 50, 100, 200],
      columns: [
        {
          field: 'name',
          label: 'Customer',
          tdClass: 'w-vgt-260',
        },
        {
          field: 'balance',
          label: 'Piutang',
          tdClass: 'w-vgt-220',
        },
        {
          field: 'status',
          label: 'Status',
        },
        {
          field: 'priceCategory',
          label: 'Kategori Harga',
          width: '180px',
        },
        {
          field: 'street',
          label: 'Alamat',
          tdClass: 'w-vgt-260',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
    }
  },
  computed: {
    ...mapFields([
      'sortOptions',
      'customerFilter',
      'customerList',
      'customerCount',
      'loadingCustomer',
      'customerCategory',
    ]),
    ...mapGetters({
      statusOptions: 'getStatusOptions',
    }),
    filterMode() {
      if (this.customerFilter.search) return true
      if (this.customerFilter.npwp) return true
      if (this.customerFilter.selectedSort.field !== 'name') return true
      if (this.customerFilter.selectedSort.sortType !== 'asc') return true
      if (this.customerFilter.customerCategory.length) return true
      if (this.customerFilter.status.id !== 1) return true
      return false
    },
  },
  watch: {
    customerFilter: {
      handler() {
        if (!this.$refs.sidebarRight.isOpen) {
          this.fetchCustomers()
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if (!this.customerList.length) {
      this.fetchCustomers()
    }
  },
  methods: {
    fetchCustomers() {
      this.loadingCustomer = true
      const customerCategory = this.customerFilter.customerCategory.length ? `[${this.customerFilter.customerCategory.map(el => el.id)}]` : null
      const search = this.customerFilter.search ? `"${this.customerFilter.search}"` : null
      const npwp = this.customerFilter.npwp ? `"${this.customerFilter.npwp}"` : null

      this.$store.dispatch('getCustomers', {
        filter: `{
          sort: "${this.customerFilter.selectedSort.field}",
          sortType: "${this.customerFilter.selectedSort.sortType}",
          status: ${this.customerFilter.status.id ?? 1},
          search: ${search},
          npwp: ${npwp},
          customerCategoryId: ${customerCategory},
        }`,
        pagination: `{
          limit: ${this.customerFilter.limit},
          offset: ${this.customerFilter.offset},
        }`,
      }).then(result => {
        this.customerCount = result.count
        this.customerList = result.customers

        this.loadingCustomer = false
      }).catch(() => {
        this.loadingCustomer = false
      })
    },
    fetchSomeStuff() {
      if (!this.customerCategory.length) {
        this.$store.dispatch('getCustomerCategory')
      }
    },
    resetFilter() {
      this.customerFilter = {
        search: '',
        npwp: '',
        customerCategory: [],
        status: { id: 1, label: 'Aktif' },
        selectedSort: {
          label: 'Nama Customer ↓',
          field: 'name',
          sortType: 'asc',
        },
        offset: 1,
        limit: 25,
      }
    },
    addToAccurate(id) {
      this.$swal({
        title: 'Tambah Customer?',
        text: 'Konfirmasi jika anda ingin menambah customer ke Accurate',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('addCustomerAccurate', id).then(data => {
            if (data.data.addCustomerAccurate.status.toLowerCase() === 'success') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil menambah customer ke Accurate!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchCustomers()
            }
          })
        }
      })
    },
    deleteCustomerData(id) {
      this.$swal({
        title: 'Hapus Customer?',
        text: 'Konfirmasi jika anda ingin menghapus customer',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('removeCustomer', id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menghapus customer',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
            this.fetchCustomers()
          })
        }
      })
    },
    restoreCustomerData(id) {
      this.$swal({
        title: 'Pulihkan Customer?',
        text: 'Konfirmasi jika anda ingin memulihkan customer',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('restoreCustomerFunc', id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil memulihkan customer',
                icon: 'ShareIcon',
                variant: 'success',
              },
            })
            this.fetchCustomers()
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';

.b-sidebar {
  .dark-layout & {
    background-color: $theme-dark-body-bg !important;
  }

  background-color: #fff !important;

  width: 360px;
}

.form-control {
  .dark-layout & {
    color: #fff !important;
  }
}
</style>

<style>
.box-status {
  height: 40px;
  width: 6px;
  border-radius: 2px;
}
</style>
